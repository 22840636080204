<section [class.dark]="dark" [class.light]="!dark">
  <div class="container">
    <div class="image-grid-layout">
      <div class="image-wrapper">
        <div class="responsive-image"></div>
      </div>
      <div class="image-content">
        <div class="row">
          <div class="col col-md-9">
            <h2>Welcome to the Siemens Business Partner training</h2>
          </div>
          <div class="col col-md-12">
            <p>
              In this training we will explain to you why integrity and compliance is so important to Siemens and our need
              for trust and co-operation in our business relationships.
            </p>

            <p>
              In addition, why we expect our Business Partners to follow similar values and principles to those which
              form the foundation for all Siemens’ business activities.
            </p>

            <p>
              You will learn about the legal environment, the Siemens compliance system, our third party due diligence process,
              the contractual obligations, and most importantly: Why <b>integrity matters – always!</b>
            </p>

            <p>The training is available in <button class="newHomeTagNavigator__tagBtn" (click)="btnClick('videos/en/');">English</button> language, however, you can chose various subtitles:</p>

            <ul class="newHomeTagNavigator__tags">
              <li class="newHomeTagNavigator__tag">
                <button class="newHomeTagNavigator__tagBtn" (click)="btnClick('videos/de/');">German</button>
              </li>
              <li class="newHomeTagNavigator__tag">
                <button class="newHomeTagNavigator__tagBtn" (click)="btnClick('videos/es/');">Spanish</button>
              </li>
              <li class="newHomeTagNavigator__tag">
                <button class="newHomeTagNavigator__tagBtn" (click)="btnClick('videos/pt/');">Portuguese</button>
              </li>
              <li class="newHomeTagNavigator__tag">
                <button class="newHomeTagNavigator__tagBtn" (click)="btnClick('videos/cn/');">Chinese</button>
              </li>
			        <li class="newHomeTagNavigator__tag">
                <button class="newHomeTagNavigator__tagBtn" (click)="btnClick('videos/fr/');">French</button>
              </li>
              <li class="newHomeTagNavigator__tag">
                <button class="newHomeTagNavigator__tagBtn" (click)="btnClick('videos/tr/');">Turkish</button>
              </li>
              <li class="newHomeTagNavigator__tag">
                <button class="newHomeTagNavigator__tagBtn" (click)="btnClick('videos/vn/');">Vietnamese</button>
              </li>
            </ul>

          </div>
        </div>
    </div>
    </div>
  </div>
</section>
