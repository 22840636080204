import { A11yModule } from '@angular/cdk/a11y';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SiLocalizationNgModule } from '@simpl/siemens-brand-localize-with-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { SiFooterModule } from '@simpl/siemens-brand-ng/footer';
import { SiBreadcrumbModule } from '@simpl/siemens-brand-ng/breadcrumb';
import { SI_VALIDATION_ERROR_RESOLVER_MAP } from '@simpl/siemens-brand-ng/form-group';
import { SiHeaderModule } from '@simpl/siemens-brand-ng/header';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WelcomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    A11yModule,
    AppRoutingModule,
    SiHeaderModule,
    SiFooterModule,
    SiBreadcrumbModule,
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SiLocalizationNgModule
  ],
  providers: [{
    provide: SI_VALIDATION_ERROR_RESOLVER_MAP,
    useValue: { required: 'Value is required' }
  }],
  bootstrap: [AppComponent]
})
export class AppModule {}
