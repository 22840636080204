import { Component } from '@angular/core';
import { DARK } from '../../../environments/theme';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  dark = DARK;

  btnClick(url: string) {
    //window.open('http://www.example.com', '_blank');
    window.location.href=url;
  };
}
